import "./App.css";
import Button from "@mui/material/Button";
import Header from "./Header.jsx";
import { Select, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Footer from "./Footer.jsx";

function Login(props) {
  const handleLogin = () => {
    const matchingStudent = studentsData.find(
      (student) =>
        student.group === props.Sem &&
        student.firstName.toLowerCase() === props.User.toLowerCase() &&
        student.lastName.toLowerCase() === props.Password.toLowerCase()
    );

    if (matchingStudent) {
      props.navigate("/Modul");
    } else {
      props.navigate("/Failed");
    }
  };

  const studentsData = [
    {
      group: 1,
      firstName: "Alexander",
      lastName: "Rühli",
      email: "alexander.ruehli@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Carmen",
      lastName: "Schwammberger",
      email: "carmen.schwammberger@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Céline",
      lastName: "Keller",
      email: "celine.keller@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Dario",
      lastName: "Heller",
      email: "dario.heller@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Denis",
      lastName: "Lüthi",
      email: "denis.luethi@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Eric",
      lastName: "Iseli",
      email: "eric.iseli@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Flavio",
      lastName: "Zettel",
      email: "flavio.zettel@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Florian",
      lastName: "Neuenschwander",
      email: "florian.neuenschwander@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Ignaz",
      lastName: "Kuczynski",
      email: "ignaz.kuczynski@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Ines",
      lastName: "Niederberger",
      email: "ines.niederberger@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Jan-David",
      lastName: "Hänisch",
      email: "jandavid.haenisch@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Jerome",
      lastName: "Seinige",
      email: "jerome.seinige@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Jonas",
      lastName: "Heinz",
      email: "jonas.heinz@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Kelan Darwin",
      lastName: "Schmitter",
      email: "kelandarwin.schmitter@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Lara",
      lastName: "Frischknecht",
      email: "lara.frischknecht@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Léa",
      lastName: "Keller",
      email: "lea.keller@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Leonardo",
      lastName: "Seminatore",
      email: "leonardo.seminatore@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Lukas",
      lastName: "Sutter",
      email: "lukas.sutter@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Manuel",
      lastName: "Aebi",
      email: "manuel.aebi@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Manuel",
      lastName: "Hubler",
      email: "manuel.hubler@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Marco",
      lastName: "Ammann",
      email: "marco.ammann@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Marco",
      lastName: "Klaus",
      email: "marco.klaus@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Mario",
      lastName: "Bay",
      email: "mario.bay@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Micha",
      lastName: "Erci",
      email: "micha.erci@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Michael",
      lastName: "Kamm",
      email: "michael.kamm@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Oliver",
      lastName: "Roost",
      email: "oliver.roost@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Pascal",
      lastName: "Schmid",
      email: "pascal.schmid@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Raffaele",
      lastName: "Boppart",
      email: "raffaele.boppart@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Sarah",
      lastName: "Schrenk",
      email: "sarah.schrenk@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Stefanie",
      lastName: "Rufer",
      email: "stefanie.rufer@students.fhnw.ch",
    },
    {
      group: 1,
      firstName: "Yonea",
      lastName: "Koch",
      email: "yonea.koch@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Artem",
      lastName: "Hukasov",
      email: "artem.hukasov@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Corina",
      lastName: "Rast",
      email: "corina.rast@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Denis",
      lastName: "Lüthi",
      email: "denis.luethi@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Artem",
      lastName: "Hukasov",
      email: "artem.hukasov@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Corina",
      lastName: "Rast",
      email: "corina.rast@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Denis",
      lastName: "Lüthi",
      email: "denis.luethi@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Fabian",
      lastName: "Gross",
      email: "fabian.gross@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Flavia",
      lastName: "Ackermann",
      email: "flavia.ackermann@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Gian Luca",
      lastName: "Schmid",
      email: "gianluca.schmid@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Gian",
      lastName: "Schneider",
      email: "gian.schneider@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Jan-David",
      lastName: "Hänisch",
      email: "jandavid.haenisch@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Janis",
      lastName: "Kramer",
      email: "janis.kramer@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Jannik",
      lastName: "Anderegg",
      email: "jannik.anderegg@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Joel Yves Erne",
      lastName: "Erne",
      email: "joelyves.erne@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Julian",
      lastName: "Heydebrand",
      email: "julian.vonheydebrand@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Marco",
      lastName: "Stampfli",
      email: "marco.stampfli@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Mattia",
      lastName: "Verzaroli",
      email: "mattia.verzaroli@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Maurice",
      lastName: "Hartig",
      email: "maurice.hartig@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Micha",
      lastName: "Tschanz",
      email: "micha.tschanz@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Miro",
      lastName: "Jutz",
      email: "miro.jutz@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Nando",
      lastName: "Amport",
      email: "nando.amport@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Nathan",
      lastName: "Matzinger",
      email: "nathan.matzinger@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Oliver",
      lastName: "Inglin",
      email: "oliver.inglin@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Oliver",
      lastName: "Roost",
      email: "oliver.roost@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Pascal",
      lastName: "Kalbermatten",
      email: "pascal.kalbermatten@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Patrick",
      lastName: "Würsten",
      email: "patrick.wuersten@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Sarah",
      lastName: "Schrenk",
      email: "sarah.schrenk@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Silas",
      lastName: "Haab",
      email: "silas.haab@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Sina",
      lastName: "Kägi",
      email: "sina.kaegi@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Sven",
      lastName: "Uythoven",
      email: "sven.uythoven@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Tiziano",
      lastName: "Caldelari",
      email: "tiziano.caldelari@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Vania Fernandes",
      lastName: "Pereira",
      email: "vania.fernandespereira@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Volodymyr",
      lastName: "Shubin",
      email: "volodymyr.shubin@students.fhnw.ch",
    },
    {
      group: 3,
      firstName: "Youssef",
      lastName: "Shamoun",
      email: "youssef.shamoun@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Alex",
      lastName: "Burà",
      email: "alex.bura@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Andrea",
      lastName: "Bricalli",
      email: "andrea.bricalli@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Benjamin",
      lastName: "Guggisberg",
      email: "benjamin.guggisberg@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Carmen",
      lastName: "Nigg",
      email: "carmen.nigg@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Dominic",
      lastName: "Schär",
      email: "dominic.schaer@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Fabian",
      lastName: "Rüfenacht",
      email: "fabian.ruefenacht@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Fabian",
      lastName: "Waltisberg",
      email: "fabian.waltisberg@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Flavia",
      lastName: "Ackermann",
      email: "flavia.ackermann@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Fredrik",
      lastName: "Lennström",
      email: "fredrik.lennstroem@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Jonas",
      lastName: "Wörgau",
      email: "jonas.woergau@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Josephine",
      lastName: "Schmidlin",
      email: "josephine.schmidlin@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Livia",
      lastName: "Rubi",
      email: "livia.rubi@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Lucas",
      lastName: "Wunderli",
      email: "lucas.wunderli@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Marion",
      lastName: "Obrist",
      email: "marion.obrist@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Marosch",
      lastName: "Novák",
      email: "marosch.novak@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Martina",
      lastName: "Meyer",
      email: "martina.meyer@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Matteo",
      lastName: "Ferrari",
      email: "matteo.ferrari@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Mattia",
      lastName: "Bärtschi",
      email: "mattia.baertschi@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Mattia",
      lastName: "Koch",
      email: "mattia.koch@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Melanie",
      lastName: "Beer",
      email: "melanie.beer@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Nadia",
      lastName: "Baumann",
      email: "nadia.baumann@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Philipp",
      lastName: "Studer",
      email: "philipp.studer2@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Priska",
      lastName: "Zatti",
      email: "priska.zatti@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Ramiro",
      lastName: "Blattner",
      email: "ramiro.blattner@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Sara",
      lastName: "Hauser",
      email: "sara.hauser@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Silvan",
      lastName: "Baumeler",
      email: "silvan.baumeler@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Stefan",
      lastName: "Koch",
      email: "stefan.koch@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Stefan",
      lastName: "Sidler",
      email: "stefan.sidler@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Théo",
      lastName: "Reibel",
      email: "theo.reibel@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Tim",
      lastName: "von Felten",
      email: "tim.vonfelten@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Timon",
      lastName: "Kuhn",
      email: "timon.kuhn@students.fhnw.ch",
    },
    {
      group: 5,
      firstName: "Yamen",
      lastName: "Nassan",
      email: "yamen.nassan@students.fhnw.ch",
    },
  ];

  return (
    <div style={{ color: "#FDE70E" }}>
      <Header style={{ color: "#FDE70E" }} />
      <Grid
        container
        spacing={3}
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ color: "#FDE70E" }}
      >
        <Grid item>
          <span></span>
        </Grid>
        <Grid item>
          <TextField
            style={{ width: "200px", height: "60px" }}
            label="Vorname"
            value={props.User}
            variant="outlined"
            onChange={(e) => props.setUser(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            style={{ width: "200px", height: "60px" }}
            label="Nachname"
            value={props.Password}
            variant="outlined"
            onChange={(e) => props.setPassword(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Select
            style={{ width: "200px", height: "60px" }}
            labelId="semester"
            id="semester"
            value={props.Sem}
            label={props.Sem}
            onChange={(e) => props.setSem(e.target.value)}
          >
            <MenuItem value={1}>Erstes Semester</MenuItem>
            <MenuItem value={2}>Zweites Semester</MenuItem>
            <MenuItem value={3}>Drittes Semester</MenuItem>
            <MenuItem value={4}>Viertes Semester</MenuItem>
            <MenuItem value={5}>Fünftes Semester</MenuItem>
            <MenuItem value={6}>Sechstes Semester</MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disabled={props.User === "" || props.Password === ""}
            onClick={handleLogin}
            style={{
              width: "200px",
              color: "black",
              fontSize: "15",
              backgroundColor: "#FDE70E",
              height: "60px",
            }}
          >
            Fortfahren
          </Button>
        </Grid>
      </Grid>
      <Footer/>
    </div>
  );
}

export default Login;
