import "./App.css";
import Header from "./Header2.jsx";
import Button from "@mui/material/Button";
import {
  TextField,
  Slider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Footer from "./Footer.jsx";

function Feedback(props) {
  const [sliderValue, setSliderValue] = React.useState(0);
  const handleclick = () => {
    const data = {
      Semester: props.Sem,
      Modul: props.selectedModule,
      Nachnme: anonymous ? "Anonym" : props.Password,
      Vorname: anonymous ? "Anonym" : props.User,
      Note: sliderValue,
      Feedback: props.Text,
    };
    exportToCSV([data], "feedback_data.csv");
    props.navigate("/Modul2");
    console.log(data);
  };
  const [anonymous, setAnonymous] = React.useState(true);
  const exportToCSV = (dataArray, fileName) => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      dataArray.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const SliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  return (
    <div>
      <Header User={props.User} Password={props.Password} />

      <div className="PageWrapper">
        <div className="FlexPage">
          <div className="row">
            <h1>Feedback zu {props.selectedModule}</h1>
          </div>

          <div className="row">
            <TextField
              sx={{ width: 700 }}
              label="Feedback"
              variant="outlined"
              multiline
              rows={5}
              onChange={(e) => props.setText(e.target.value)}
            />
          </div>
          <div className="row">
            <h2>Note 1-6</h2>
          </div>
          <div className="row">
            <Slider
              style={{ width: "700px" }}
              aria-label="discrete-slider"
              valueLabelDisplay="auto"
              marks
              min={1}
              max={6}
              step={0.5}
              onChange={SliderChange}
              defaultValue={3.5}
            />
          </div>
          <div className="row">
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  onChange={(e) => setAnonymous(e.target.checked)}
                />
              }
              label="Anonymität"
            />

            <Button
              variant="contained"
              onClick={handleclick}
              style={{ color: "black", backgroundColor: "#FDE70E" }}
            >
              Senden
            </Button>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Feedback;
