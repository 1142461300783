import "./App.css";
import Button from "@mui/material/Button";
import Header from "./Header.jsx";
import { Select, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Footer from "./Footer.jsx";

function Failed(props) {
  const handleback = () => {
    props.navigate("/");
  };
  const handleend = () => {
    props.navigate("/Logout");
  };

  return (
    <div>
      <Header />
      <div className="PageWrapper">
        <div className="FlexPage">
          <div>
            <h1>Student nicht gefunden</h1>
          </div>

          <div className="row">
            <Button
              variant="contained"
              onClick={handleback}
              style={{
                marginRight: "10px",
                color: "black",
                backgroundColor: "#FDE70E",
              }}
            >
              Zurück zum login
            </Button>

            <Button
              variant="contained"
              onClick={handleend}
              style={{ color: "black", backgroundColor: "#FDE70E" }}
            >
              Session Beenden
            </Button>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Failed;
