import "./App.css";
import Header from "./Header2.jsx";
import Footer from "./Footer.jsx";

function Logout(props) {
  return (
    <div>
      <Header User={props.User} Password={props.Password} />
      <div className="PageWrapper">
        <div className="FlexPage">
          <div className="row">
            <h1>
              Herzlichen Dank dass sie unsere Feedback App verwendet haben.
            </h1>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Logout;
