import React from "react";
import logog2022 from "./geomaticlogo.png";
import "./App.css";

function Footer(props) {
  
   
  return (
    <footer
      className="App-header"
      style={{
        backgroundColor: "#FDE70E",
        borderTop: "2px solid #000000",
        borderBottom:"#FDE70E",
      }}
    >
      <img style={{ height: 40,marginLeft:"40px" }} src={logog2022} alt="logo" />
      <div id="HeaderText" style={{ color: "black",marginLeft:"250px" }}>
        Dies ist keine offizielle FHNW Website
      </div>
      <div id="HeaderText" style={{ color: "black", marginRight:"40px" }}>©Copyright by Baum und Steinbock
        
      </div>
    </footer>
  );
}

export default Footer;
